.documents-grid-header{
    margin-top: 3%;
    font-size: 250%;
}


#go-back-button {
    transform: scale(1.3);
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    margin-left: 10%;
    margin-top: 3%;
    margin-bottom: -2%;
    top: 20px;
    left: 20px;
}

#go-back-button:hover{
    color: #0056b3;
}