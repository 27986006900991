/* ForumsList.css */

.forums-list-container {
  padding: 20px;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 8%;
  margin-top: 2%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.forums-list-container h1 {
  text-align: center;
  word-wrap: break-word; /* Ensures words break correctly */
  overflow-wrap: break-word; /* Ensures words break correctly */
  white-space: pre-wrap; /* Ensures long words break to the next line */
}

.forums-list-container ul {
  list-style: none;
  padding: 0;
}

.forums-list-container li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-align: center;
  word-wrap: break-word; /* Ensures words break correctly */
  overflow-wrap: break-word; /* Ensures words break correctly */
  white-space: pre-wrap; /* Ensures long words break to the next line */
  margin-left: 5%;
  margin-right: 5%;
}

.forums-list-container li:hover {
  background-color: #f0f0f0;
}

.forums-list-container h2 {
  margin: 0;
}

.forums-list-container p {
  margin: 5px 0;
  color: #555;
}

.add-forum-button {
  position: relative;
  width: 60px;
  height: 59px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 26px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.add-forum-button:hover {
  background-color: #0056b3;
}
