
  .fun-loading-animation {
    display: flex;
    height: 60vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  .ball {
    width: 50px;
    height: 50px;
    background-color: #4CAF50;
    border-radius: 50%;
    position: relative;
    animation: bounce 1.5s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-150px);
    }
  }
  
  p {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .footer {
    width: 100%;
  }
  