.future-meetings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  padding: 15px;
  margin-bottom: 4%;
}

.meeting-card {
  color: #333;
  border-radius: 8px;
  padding: 25px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.meeting-title {
  font-size: 22px;
}

.meeting-date{
  font-style: italic;
}

.meeting-time{
  margin-top: -2%;
  font-weight: bold;
}

.meeting-description {
  word-wrap: break-word;
}

.meeting-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Dynamic gradients with pastel colors */
.meeting-card.gradient-0 {
  background: linear-gradient(to right, #b0e6e0, #7ff3ed); /* Powder Blue to Peach Puff */
}

.meeting-card.gradient-1 {
  background: linear-gradient(to right, #FFB6C1, #FFE4E1); /* Light Pink to Misty Rose */
}

.meeting-card.gradient-2 {
  background: linear-gradient(to right, #FFFACD, #f7ed83); /* Lemon Chiffon to Lavender */
}

.meeting-card.gradient-3 {
  background: linear-gradient(to right, #AFEEEE, #F0FFF0); /* Pale Turquoise to Honeydew */
}

.meeting-card.gradient-4 {
  background: linear-gradient(to right, #E0FFFF, #4ce99d); /* Light Cyan to Khaki */
}

.editing-meeting-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 90; /* Ensure it's on top of everything */
}

.meeting-date strong {
  font-weight: bold;
}

#pencil-icon {
  display: fixed;
  margin: auto;
  position: relative;
  cursor: pointer;
  font-size: 100%;
  color: #234cee;
}