/* NewTask.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.newtask-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
  z-index: 1000;
}

.newtask-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.newtask-form {
  display: flex;
  flex-direction: column;
}

.newtask-form input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

#submit-button-container {
  text-align: center;
}

#submit-button {
  padding: 10px 20px;
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

#submit-button:hover {
  background-color: #1565c0;
}

.close-button-container {
  position: relative;
  margin-top: -1%;
  text-align: center;  
  margin-right: -4%;
}

.close-button-container button{
  position: relative;
  cursor: pointer;
  font-size: 22px;
  width: 30px;
  background-color: #1e88e5;
  color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: auto;
  padding: 3px;
}

.close-button-container button:hover{
  background-color: #1565c0;
}