
.meetings-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  margin-bottom: 8%;
  margin-top: 2%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.no-meetings {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

.meetings-options-container .buttons-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

#meetings-header {
  font-size: 2.1em;
}
