/* ParticipantList.css */

.participant-list-container {
    max-width: 960px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 3%;
    margin-bottom: 5%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.year-section {
    margin-bottom: 40px;
}

.participants-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 25px;
}

.participant-card {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
}

#no-participants-found{
    margin-top: 8%;
}

.participant-card img {
    width: 105px;
    height: 120px;
    border-radius: 20%;
    margin-bottom: 10px;
}

.participant-card p {
    margin: 0;
    font-weight: bold;
}

.participant-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.small-text {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 2%;
}

.participant-name{
    font-size: 1.3rem;
    font-weight: bold;
}

.year-header{
    margin-top: 10%;
    font-size: 180%;
}

.group-header{
    font-size: 240%;
    margin-bottom: -5%;
}

#go-back-button {
    transform: scale(1.3);
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    top: 20px;
    left: 20px;
}

#go-back-button:hover{
    color: #0056b3;
}

#profile-picture {
    width: 180px;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
    filter: blur(10px); /* Initial blur for low-res preview */
    opacity: 0.1;
    transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
}

#profile-picture.loaded {
    opacity: 1;
    filter: blur(0);
}
