.options-grid {
  transform: scale(1.1);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 5px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 7%;
    margin-top: 3%;
    border-radius: 10px;
  }
  
  .menu-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .menu-option:hover {
    transform: scale(1.05); /* Scale the button slightly on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add a bigger shadow on hover */
  }
  
  .menu-option img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .menu-option p {
    font-size: 18px;
    text-align: center;
  }


  /* Define gradient colors for each option */
.menu-option:nth-child(1) {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  }
  .menu-option:nth-child(2) {
    background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%);
  }
  .menu-option:nth-child(3) {
    background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
  }
  .menu-option:nth-child(4) {
    background: linear-gradient(135deg, #8f40ba 0%, #e72f3b 100%);
  }
  .menu-option:nth-child(5) {
    background: linear-gradient(135deg, #96fbc4 0%, #f9f586 100%);
  }
  .menu-option:nth-child(6) {
    background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
  }
  .menu-option:nth-child(7) {
    background: linear-gradient(135deg, #4ae47e 0%, #38f9d7 100%);
  }
  .menu-option:nth-child(8) {
    background: linear-gradient(135deg, #30cfd0 0%, #330867 100%);
  }
  
#main-menu-title {
    font-size: 2em;
    text-align: center;
    margin-top: 5%;
}

#option-text {
  font-weight: 600;
}