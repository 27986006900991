/* CommentSection.css */

.comment-section {
  margin-top: 15%;
  width: 100%;
  padding: 5%;
}

.comments-list {
  margin-top: 0%;
  width: 100%;
}

.comments-header {
  font-size: 22px;
  position: relative;
  float: left;
  margin-top: -8%;
}

.comment-bubble {
  display: flex;
  flex-direction: column;
  background-color: #e0f7fa;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
  max-width: 80%;
  word-wrap: break-word; /* Ensures words break correctly */
  overflow-wrap: break-word; /* Ensures words break correctly */
  white-space: pre-wrap; /* Ensures long words break to the next line */
  pointer-events: auto;
}

.comment-bubble::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #e0f7fa;
  border-bottom: 10px solid transparent;
}

.comment-author {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
}

.comment-author-picture {
  width: 45px;
  height: 50px;
  border-radius: 20%;
  margin-right: 10px;
}

.comment-text {
  text-align: left;
  margin-top: 2.5%;
  margin-left: 1%;
  flex-wrap: wrap;
  word-wrap: break-word; /* Ensures words break correctly */
  overflow-wrap: break-word; /* Ensures words break correctly */
  white-space: pre-wrap; /* Ensures long words break to the next line */
}

.add-comment {
  margin-top: 20px;
}

.add-comment textarea {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 1%;
  margin-left: -10%;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px ;
}

.add-comment-button {
  background-color: #28a745;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.add-comment button:hover {
  background-color: #218838;
  font-weight: bold;
}

#creation-date-comment {
  display: flex;
  justify-content: end;
  margin-top: -4.5%;
  font-style: italic;
  font-size: 15px;
  margin-right: 1%;
}

#edit-comment-text{
  position: relative;
  margin-left: 80%;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  width: 200%;
}

#pencil-icon {
  cursor: pointer;
  font-size: 100%;
  width: 100%;
  padding: auto;
  color: #234cee;
}

#edit-delete-container {
  display: flex;
  flex-direction: row-reverse;
  width: 120%;
}

#confirm-delete{
  display: block;
  position:static;
}

#confirm-delete button{
  margin-left: 10px;
}

#comment-actions {
  display: flex;
  pointer-events: auto;
}

#heart-svg {
  width: 6%;
  margin-bottom: 2.5%;
  cursor: pointer;
  pointer-events: auto;
  z-index: 200;
}

#like-count {
  margin-left: -0.5%;
}

.edit-delete-container {
  position: relative;
  display: inline-block;
}

.edit-menu-comment {
  position: absolute;
  right: -5%;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 5px;
  z-index: 1;
}

.edit-menu-comment button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
}

.edit-menu-comment button:hover {
  background-color: #f0f0f0;
}

.comment-bubble-container {
  position: relative;
}

.confirm-delete-comment {
  position: absolute;
  width: 25%;
  top: 90%;
  left: 60%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 2;
}

.confirm-delete-comment button{
  width: 20%;
  margin: 2%;
}