#editing-club-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#editing-club-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

#editing-club-container input {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
}

#editing-club-container textarea {
    width: 90%;
    margin: 10px 0;
    height: 130px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
}

#save-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0056b3;
    color: white;
    margin-top: 20px;
}

#editing-club-container button:hover {
    background-color: #002b59;
}

#close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    padding-bottom: 8px;
    padding-right: 12px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background-color: #d01c0f;
    color: white;
    border-radius: 20%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#close-button:hover {
    background-color: #d32f2f;
}

#blurred-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
