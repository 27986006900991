.add-document-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); /* Blur the background */
    z-index: 1050; /* High z-index to ensure it's above other content */
}

.add-document-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px; /* Ensure it doesn't get too large on wider screens */
}

.add-document-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.add-document-container input {
    width: 85%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.add-document-container .buttons {
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: 10%;
    margin-left: 5%;
    margin-bottom: 2%;
    margin-top: 2%;
}

.add-document-container .buttons button {
    flex: 1 1 45%;
    padding: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button{
    background-color: #00b398;
    color: white;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

.close-button {
    background-color: #6c757d;
    color: white;
    border: none;
}

.add-document-container .buttons button:last-child {
    margin-right: 0;
}
