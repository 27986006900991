.clubs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    margin-bottom: 6%;
    background-color: #f4f4f4;
    border-radius: 10px;
}

.club-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.club-option {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    color: black;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 120px;
}

.club-option:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Dynamic gradients for each option */
.club-option.gradient-0 { background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%); }
.club-option.gradient-1 { background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); }
.club-option.gradient-2 { background: linear-gradient(135deg, #e85b5b 0%, rgb(255, 213, 220) 100%); }
.club-option.gradient-3 { background: linear-gradient(135deg, #5bb7e8 0%, rgb(215, 232, 255) 100%); }
.club-option.gradient-4 { background: linear-gradient(135deg, #c59446 0%, rgb(255, 253, 215) 100%); }
.club-option.gradient-5 { background: linear-gradient(135deg, #4846c5 0%, rgb(215, 225, 255) 100%); }
.club-option.gradient-6 { background: linear-gradient(135deg, #a5eaa5 0%, rgb(177, 215, 191) 100%); }
.club-option.gradient-7 { background: linear-gradient(135deg, #766bbe 0%, rgb(177, 187, 215) 100%); }
