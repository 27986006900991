/* NewNoteForm.css */

.note-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20%;
    width: 40%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 51;
}

.note-input input,
.note-input textarea {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
}

.note-input button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0056b3;
    color: white;
    margin-top: 10px;
}

.note-input .close-button {
    position: absolute;
    top: -5%;
    right: -1%;
    border: none;
    font-size: 100%;
    cursor: pointer;
    background-color: #0056b3;
}

.note-input button:hover {
    background-color: #002b59;
}

.note-input .close-button:hover {
    background-color: #f44336;
}

.color-picker {
    width: 60px;
    height: 60px; 
    padding: 0;
    border: 2px solid #007bff; 
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer; 
}