.clubs-section-container {
    padding: 20px;
    margin: auto;
    margin-bottom: 2%;
    margin-top: 1%;
    max-width: 1200px; /* Adjust width as needed */
}

#clubs-section-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
    color: #333;
    margin-top: 4%;
}
