.meetings-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.buttons-container {
  margin-bottom: 20px;
}

.buttons-container button {
  background-color: #00b398;
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.buttons-container button:hover {
  background-image: linear-gradient(to left, #6a11cb 0%, #2575fc 100%);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.buttons-container button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
