.profile-section-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #e8fdfc;
}

.profile-content-container {
    max-width: 60%;
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    padding-top: 4%;
    margin-top: 1%;
    margin-bottom: 3%;
}

.profile-header {
    text-align: center;
    margin-bottom: 20px;
}

.profile-header h1 {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 30px;
    margin-bottom: 1%;
}

.profile-header p {
    margin: 5px 0;
    font-size: 16px;
    color: #7f7d7d;
    margin-top: 1.5%;
}

.profile-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 5%;
}

.profile-about, .profile-interests, .profile-contact, .profile-team {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-about h2, .profile-interests h2, .profile-contact h2, .profile-team h2 {
    margin-top: 0;
}

.profile-interests ul {
    padding-left: 20px;
}

.profile-contact p {
    margin: 5px 0;
}

.profile-contact a {
    color: #007bff;
    text-decoration: none;
}

.profile-contact a:hover {
    text-decoration: underline;
}

.profile-picture {
    display: block;
    margin: 0 auto;
    width: 20%;
    height: auto;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 5%;
}

/* Edition */

/* Conditional styles for editing mode */
.profile-section-main-container.editing-mode .profile-content-container {
    max-width: 140%;
    width: 50%;
}

/* Styles for editable inputs and buttons */
.profile-header input[type="text"],
.profile-header input[type="file"] {
    width: 40%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile-body input[type="email"],
.profile-body input[type="text"] {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile-body textarea {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90%;
    height: 60%;
    margin-bottom: 25%;
    resize: none;
}

.profile-buttons {
    text-align: center;
    margin-top: 20px;
}

.profile-buttons button {
    padding: 10px 20px;
    margin: 5px;
    width: auto;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #00b398;
    color: white;
}

.profile-buttons button:hover {
    background-color: #7944ec;
}

.profile-buttons button.cancel-button {
    background-color: #f44336;
}

.profile-buttons button.cancel-button:hover {
    background-color: #d32f2f;
}

/* Editing mode labels */

/* Styles for labels */
.profile-header label,
.profile-body label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
}

#university-name {
        margin-top: -0.5%;
    }

#edit-profile-button{
    margin-top: 2%;
    padding: 10px 20px;
    width: auto;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #00b398;
    color: white;
}

#edit-profile-button:hover {
    background-color: #7944ec;
}

#profile-university-p {
    margin-top: -3%;
}

#interests-list{
    font-size: 18px;
}

#participant-text{
    padding-left: 15px;
    padding-right: 15px;
}