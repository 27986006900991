/* EditingComment.css */
.editing-comment-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .editing-comment-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 550px;
    z-index: 100;
  }
  
  .editing-comment-content h2 {
    margin-top: 0;
  }
  
  .editing-comment-content textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .editing-comment-buttons {
    display: flex;
    justify-content: center;
  }
  
  .editing-comment-buttons button {
    margin-left: 15px;
    width: 30%;
    border-radius: 3px;
    padding: 5px;
  }

  #save-comment-edit-button{
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }

  #cancel-comment-edit-button{
    background-color: #f44336;
    color: white;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }

  #editing-comment-textarea{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }

