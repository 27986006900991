.schedule-event-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* ensure it's above other content */
}

.schedule-event-form {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 440px; /* Adjust based on your preference */
    word-break: break-all;
    word-wrap: break-word;
}

#form {
    margin-left: -10%;
}

#schedule-event-input-1 {
    width: 100%;
    padding: 10px;
    margin-top: -10px;
    border-radius: 5px;
}

#schedule-event-input-2 {
    width: 100%;
    padding: 10px;
    margin-top: -10px;
    border-radius: 5px;
}

.schedule-event-button{
    width: 70%;
    padding: 15px;
    margin-top: 15%;
    margin-bottom: 5%;
    border-radius: 5px;
    background-color: #6b48b1;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
}

.schedule-event-button:hover {
    background-color: #5a39a0;
}

#input-1-label {
    padding: 10px;
    font-weight: bold;
}

#input-2-label {
    padding: 10px;
    font-weight: bold;
}

#close-button {
    background-color: #d14c4c;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 12px;
    cursor: pointer;
}