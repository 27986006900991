#editing-note-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 450px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#editing-note-container input,
#editing-note-container textarea {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    resize: none;
}

#editing-note-container textarea{
    height: 450px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#note-editing-title{
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
}

#editing-note-container button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0056b3;
    color: white;
    margin-top: 10px;
}

#editing-note-container #close-button {
    position: absolute;
    top: -20px;
    right: -10px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background-color: #d32f2f;
    color: white;
    border-radius: 20%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#editing-note-container button:hover {
    background-color: #002b59;
}

#editing-note-container #close-button:hover {
    background-color: #f44336;
}

#color-picker {
    width: 60px;
    height: 60px; 
    padding: 0;
    border: 2px solid #007bff; 
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer; 
}

#blurred-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
