/* Footer.css */
#permanent-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #00b398; /* Adjust as needed */
  border-top: 2px solid #686868;
  border-bottom: 2px solid #686868;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-column {
  flex: 1;
  text-align: center; /* Adjust as needed */
}

#footer-gorom-url {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: white;
  -webkit-text-stroke-color: black;
}

#footer-contact-info {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: white;
  -webkit-text-stroke-color: black;
}

#footer-logo {
  max-height: 60px; /* Adjust as needed */
}
