/* ForumEntry.css */

.forum-entry-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 20px auto;
  position: relative; /* Ensure positioning works for child elements */
}

.go-back-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.forum-title {
  margin-top: 10%;
  padding-left: 7%;
  padding-right: 7%;
}

.go-back-button:hover {
  color: #0056b3;
}

.forum-author {
  font-style: italic;
  margin-bottom: 40px;
}

#creation-date {
  margin-top: 6%;
  text-align: end;
  margin-right: 5%;
  display: block;
  font-style: italic;
  margin-bottom: 20px;
}

.forum-content {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding-top: 1%;
}

.forum-content img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

#forum-text-content {
  padding-left: 40px;
  padding-right: 40px;
  word-wrap: break-word; /* Ensures words break correctly */
  overflow-wrap: break-word; /* Ensures words break correctly */
  white-space: pre-wrap; /* Ensures long words break to the next line */
}

.add-comment {
  margin-top: 20px;
}

.add-comment textarea {
  margin-bottom: 2%;
  margin-top: 5%;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
  width: 80%;
  height: 120px;
  padding: 30px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

.add-comment button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin-left: 38%;

}

.add-comment button:hover {
  background-color: #218838;
}

#edit-delete-container-forum{
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: -5%;
}

#edit-forum-text{
  display: flex;
  margin-left: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
}

#pencil-icon-forum {
  align-items: start;
  cursor: pointer;
  font-size: 100%;
  color: #234cee;
  margin-left: 9%;
}

#heart-container {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 7%;
  align-items: stretch;
}

#heart-svg {
  width: 6%;
  cursor: pointer;
  margin-top: 1.5%;
}

#like-count {
  margin-right: -6.5%;
  margin-top: 2%;
}

.blurred {
  filter: blur(5px);
}

.edit-menu-forum {
  position: absolute;
  left: 13%;
  right: inherit;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 5px;
  z-index: 1;
}

.edit-menu-forum button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
}

.edit-menu-forum button:hover {
  background-color: #f0f0f0;
}