/* NotesGrid.css */

.notes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
    z-index: 1;
    margin-top: -2.5%;
    margin-bottom: 2%;
}

.note {
    display: block;
    text-align: center;
    background-color: #ffeb3b;
    border-radius: 10px;
    margin: 0.4%;
    padding: 17px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    min-width: 18%;
    max-width: 18%;
    word-wrap: break-word;
    font-size: 18px;
}

.note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#pencil-icon {
    display: fixed;
    margin: auto;
    position: relative;
    cursor: pointer;
    font-size: 100%;
    color: #234cee;
}

.menu {
    display: block;
    position: absolute;
    left: 150px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10;
}

.menu button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    text-align: left;
    width: 100%;
}

.menu button:hover {
    background-color: #f0f0f0;
}

.confirm-delete {
    text-align: center;
    margin-top: 10px;
}

.confirm-delete p {
    margin: 5px 0;
}

.confirm-delete button {
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
}

#note-author {
    text-align: right;
    font-size: 90%;
    color: #2c2c2c;
    margin-left: 2%;
    margin-top: -1%;
    margin-bottom: -10%;
}

#note-title {
    text-align: center;
    font-size: 115%;
    margin-top: 5%;
    padding: 5px;
}

#note-description {
    font-size: 90%;
    margin-top: 5%;
    padding-left: 15px;
    padding-right: 15px;
}

.note-description-container {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
}

#heart-container-note {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 1%;
}
  
#heart-svg-note {
    width: 15%;
    cursor: pointer;
    margin-top: 1.5%;
}

#like-count-note {
    margin-right: -1%;
    margin-top: 7.5%;
}