.documents-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    margin: 0 auto;
    margin-top: 2%;
    background-color: #f4f4f4;
    border-radius: 10px;
    width: 80%;
    margin-bottom: 6%;
}

.document-option {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 90px; /* Consistent height for all buttons */
    overflow: hidden; /* Ensures no overflow of content */
}

.document-option:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.document-icon {
    width: 50px;
    margin-right: 1%;
}

/* Dynamic gradients based on the nth-child to create visual diversity */
.document-option:nth-child(1) { background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%); }
.document-option:nth-child(2) { background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); }
.document-option:nth-child(3) { background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%); }
.document-option:nth-child(4) { background: linear-gradient(135deg, #a65ccb 0%, #db5861 100%); }
.document-option:nth-child(5) { background: linear-gradient(135deg, #96fbc4 0%, #f9f586 100%); }
.document-option:nth-child(6) { background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%); }
.document-option:nth-child(7) { background: linear-gradient(135deg, #43e97b 0%, #38f9d7 100%); }
.document-option:nth-child(8) { background: linear-gradient(135deg, #30cfd0 0%, #b781f9 100%); }
.document-option:nth-child(9) { background: linear-gradient(135deg, #e3e3e3 0%, #7591ed 100%); }
.document-option:nth-child(10) { background: linear-gradient(135deg, #fbc686 0%, #f6ae48 100%); }
.document-option:nth-child(11) { background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%); }
.document-option:nth-child(12) { background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); }
.document-option:nth-child(13) { background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%); }
.document-option:nth-child(14) { background: linear-gradient(135deg, #a65ccb 0%, #db5861 100%); }
.document-option:nth-child(15) { background: linear-gradient(135deg, #96fbc4 0%, #f9f586 100%); }
.document-option:nth-child(16) { background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%); }
.document-option:nth-child(17) { background: linear-gradient(135deg, #43e97b 0%, #38f9d7 100%); }
.document-option:nth-child(18) { background: linear-gradient(135deg, #30cfd0 0%, #b781f9 100%); }
.document-option:nth-child(19) { background: linear-gradient(135deg, #e3e3e3 0%, #7591ed 100%); }
.document-option:nth-child(20) { background: linear-gradient(135deg, #fbc686 0%, #f6ae48 100%); }
.document-option:nth-child(21) { background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%); }
.document-option:nth-child(22) { background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); }
.document-option:nth-child(23) { background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%); }
.document-option:nth-child(24) { background: linear-gradient(135deg, #a65ccb 0%, #db5861 100%); }
.document-option:nth-child(25) { background: linear-gradient(135deg, #96fbc4 0%, #f9f586 100%); }
.document-option:nth-child(26) { background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%); }
.document-option:nth-child(27) { background: linear-gradient(135deg, #43e97b 0%, #38f9d7 100%); }
.document-option:nth-child(28) { background: linear-gradient(135deg, #30cfd0 0%, #b781f9 100%); }
.document-option:nth-child(29) { background: linear-gradient(135deg, #e3e3e3 0%, #7591ed 100%); }
.document-option:nth-child(30) { background: linear-gradient(135deg, #fbc686 0%, #f6ae48 100%); }

.document-search-input {
    width: 60%; /* Full width of the container */
    padding: 10px 15px; /* Padding for better text visibility */
    margin-bottom: 20px; /* Space between search bar and grid */
    border: 2px solid #ccc; /* Subtle border styling */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    outline: none; /* Remove the default focus outline */
    font-size: 16px; /* Slightly larger font for better readability */
    color: #333; /* Darker text color for contrast */
    background-color: #f8f8f8; /* Light background color */
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transitions for interactions */
}

.document-search-input:focus {
    border-color: #007bff; /* Highlight color when focused */
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Brighter shadow on focus */
}

.document-search-input::placeholder {
    color: #888; /* Lighter color for placeholder text */
}

.add-document-button {
    background-color: #010801;
    color: white;
    border: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin-top: 12%;
    margin-left: 10%;
    font-size: 30px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-bottom: 7%;
}

.add-document-button:hover {
    background-color: #45a049;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.documents-container .add-document-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to cover other content */
}