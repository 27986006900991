/* AddForumEntry.css */

.add-forum-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .add-forum-entry textarea {
    width: 100%;
    height: 300px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
    font-size: 16px;
  }

  .add-forum-entry input {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
    font-size: 18px;
    font-weight: bold;
  }
  
  .add-forum-entry button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }
  
  .add-forum-entry button:hover {
    background-color: #0056b3;
  }
  
  .add-forum-entry .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
    background-color: #d32f2f;
  }
  
  .add-forum-entry .close-button:hover {
    color: #f44336;
  }
  
  #add-forum-title{
    margin-top: 3%;
  }