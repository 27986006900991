#editing-event-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* Ensure it's on top of everything */
  }
  
  #editing-event-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    z-index: 10000;
    position: relative;
  }
  
  #close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  #form-grid {
    display: grid;
    grid-template-columns: 1fr 2fr; /* 1/3 for labels, 2/3 for inputs */
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  
  #form-grid label {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  
  #form-grid input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #007bff;
  }
  
  .edit-icon:hover {
    color: #0056b3;
  }

  #save-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 5%;
    font-size: 16px;
  }
  
  #delete-button {
    background-color: #f44336; /* Red color for delete */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  #delete-confirmation {
    margin-top: 20px;
    background-color: #f8d7da;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
  }
  
  #delete-confirmation button {
    background-color: #f44336; /* Red color for confirm delete */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  #delete-confirmation button:last-child {
    background-color: #6c757d; /* Grey color for cancel */
  }
  