.landing-page {
margin: 0;
padding: 0;
position: relative;
overflow: hidden;
}

.landing-page.loading {
background-color: black;
}

.header {
  margin-top: 9%;
  margin-bottom: 12%;
  text-align: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.95);
}

.title {
  margin-top: -25px;
  margin-left: 20px;
  font-size: 3.4rem;
  color: white;
}

.gorom-section {
margin-top: 105%;
}

.gorom-section,
.seed-section,
.login-section {
  padding: 2rem;
  color: white;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.landing-footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
  margin-bottom: 1%;
  margin-top: 100%;
  z-index: 10;
}

.video-background {
  display: flex;
  position: fixed;
  top: -60%;
  bottom: 10%;
  left: 0%;
  margin-top: 0%;
  width: 100%;
  z-index: -1;
}

#youtube-video-container {
  background-color: rgba(0, 0, 0, 0.95);
  display: fixed;
  overflow: hidden;
}

.content {
  position: relative;
}

.seed-main-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 1%;
  z-index: 10;
}

.seed-button {
  margin: 0 1rem;
  padding: 1.2rem 1.2rem;
  font-size: 1.3rem;
  font-weight: 590;
  width: 17.5%;
  height: 5%;
  background-color: #008cb3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }

.seed-button:hover {
  transition: background-color 1s ease;
  transform: scale(1.02);
  background-color: #00b398;
  font-weight: 600;
}

#learn-intranet-header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 12%; 
  color: white;
  font-size: 3rem;
  -webkit-text-stroke: 1.5px black;
  
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-container img {
  height: 100%;
  width: 40%;
  margin-top: 52%;
  margin-left: -700%;
  cursor: pointer;
}

#seed_logo_img {
  margin-top: 1%;
}

.intranet-about-container {
  display: static;
}

#about-intranet-header {
  color: white;
  font-size: 2.8rem;
  -webkit-text-stroke: 1px black;
  margin-top: 55%;
  background-color: rgba(0, 0, 0, 0.80);
  width: 32%;
  margin-left: 35%;
  padding: 0.3%;
  border-radius: 1%;
}

#about-intranet-text {
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  line-height: 1.8rem;
  font-size: 1.3rem;
  -webkit-text-stroke: 0.2px black;
  margin-bottom: 1%;
  background-color: rgba(0, 0, 0, 0.80);
  padding: 1%;
  border-radius: 1%;
}

#last-about-text {
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  line-height: 1.8rem;
  font-size: 1.3rem;
  -webkit-text-stroke: 0.2px black;
  margin-bottom: 2.5%;
  background-color: rgba(0, 0, 0, 0.80);
  padding: 1%;
  border-radius: 1%;
}

#gorom_logo_img {
  margin-top: -0.5%;
  padding: 0.5%;
  height: 2%;
  width: 10%;
  background-color: white;
  border-radius: 5%;
}

#gorom-logo-container {
  background-color: rgba(0, 0, 0, 0.95);
}

#by-text {
  color: white;
  margin-top: 2%;
  font-size: 1.15rem;
  font-weight: 600; 
}

#intranet-info-container {
  display: fixed;
  padding: 0.5%;
  margin-bottom: 30%;
  z-index: 20;
  
}
  
#landing-background-img{
    position: flex;
    width:100%;
    height: 25%;
    margin-top: -100%;

  }

#landing-background-img-2{
  position: flex;
  width:100%;
  height: 25%;
  }

.img-1-container {
  display: static;
  z-index: 5;
  margin-top: -57%;
}

.img-2-container {
  display: relative;
  z-index: 5;
}

.intranet-header-container {
  display: flex;
  justify-content: center;
}

.background-img-container {
  display: fixed;
  overflow: hidden;
}

.background-img-container-2 {
  display: flex;
  overflow: hidden;
}

.footer-main-container {
  display: static;
  background-color: #333;
  z-index: 11;
  height: 60px;
  margin-top: -64%;
  margin-bottom: 3%;
}

#footer-gorom-url-landing {
  color: white;
  font-weight: bold;
}

#footer-contact-info-landing {
  margin-top: -0.5%;
  color: white;
}

@media (min-width: 5120px) {

  .header {
    height: 100%;
    margin-top: 9%;
  }

  #seed_logo_img {
    transform: scale(2.7);
    margin-top: 10%;
  }

  .title {
    transform: scale(2.7);
    margin-top: 4.2%;
  }

  .seed-main-buttons-container{
    transform: scale(2.1);
    width: 70%;
    margin: auto;
    margin-top: 4%;
  }

  #by-text {
    transform: scale(2.5);
    margin-top: 3%;
  }

  #gorom_logo_img {
    margin-top: 1%;
    margin-bottom: 2%;
  }

  #learn-intranet-header {
    transform: scale(2.1);
    margin-left: 2%;
    margin-top: 3%;
  }

  .arrow-container {
    transform: scale(2.1);
  }

  #arrow {
    margin-right: 120%;
    margin-top: 80%;
  }

  .video-background {
    margin-top: 0%;
    width: 100%;
  }

  #landing-background-img {
    margin-top: -70%;
  }

  #intranet-info-container {
    transform: scale(2.1);
    margin: auto;
    width: 45%;
    margin-left: 26%;
  }

  #about-intranet-header {
    margin-top: 80%;
  }

  .footer-main-container {
    transform: scale(2.4);
    margin: auto;
    width: 100%;
    height: 154px;
  }

  .landing-footer {
      margin-top: 70%;
  }

  #footer-contact-info-landing {
    margin-top: -0.3%;
    color: white;
  }
  }

  @media (min-width: 2550px) and (max-width: 3839px) {

    .header {
      height: 85%;
      margin-left: -5%;
      margin-top: 5%;
    }
  
    #seed_logo_img {
      transform: scale(1.3);
      margin-top: 7%;
    }
  
    .title {
      transform: scale(1.5);
      margin-top: 1%;
    }
  
    .seed-main-buttons-container {
      transform: scale(1.3);
      width: 70%;
      margin: auto;
      margin-top: 3%;
    }
  
    #by-text {
      transform: scale(1.4);
      margin-top: 2%;
    }
  
    #gorom_logo_img {
      margin-top: 0.5%;
      margin-bottom: 1%;
    }
  
    #learn-intranet-header {
      transform: scale(1.2);
      margin-left: 3%;
      margin-top: 2%;
    }
  
    .arrow-container {
      transform: scale(1.2);
    }
  
    #arrow {
      margin-right: 60%;
      margin-top: 50%;
    }
  
    .video-background {
      margin-top: 10%;
    }
  
    #landing-background-img {
      margin-top: -130%;
    }
  
    #intranet-info-container {
      transform: scale(1.4);
      margin: auto;
      margin-left: 10%;
      width: 70%;
    }
  
    #about-intranet-header {
      margin-top: 65%;
    }
  
    .footer-main-container {
      transform: scale(1.3);
      margin: auto;
      width: 100%;
      height: 120px;
      margin-left: -3%;
    }
  
    .landing-footer {
      margin-top: 55%;
    }

    #footer-gorom-url-landing {
      color: white;
      font-weight: bold;
    }
    
    #footer-contact-info-landing {
      margin-top: -0.5%;
      color: white;
    }
  }

  @media (min-width: 3840px) and (max-width: 5119px) {

    .header {
      height: 90%;
    }
  
    #seed_logo_img {
      transform: scale(2.0);
      margin-top: 8%;
    }
  
    .title {
      transform: scale(2.0);
      margin-top: 3.5%;
    }
  
    .seed-main-buttons-container {
      transform: scale(1.8);
      width: 70%;
      margin: auto;
      margin-top: 3.5%;
    }
  
    #by-text {
      transform: scale(2.0);
      margin-top: 2.5%;
    }
  
    #gorom_logo_img {
      margin-top: 0.8%;
      margin-bottom: 1.5%;
    }
  
    #learn-intranet-header {
      transform: scale(1.8);
      margin-left: 2.5%;
      margin-top: 2.5%;
    }
  
    .arrow-container {
      transform: scale(1.8);
    }
  
    #arrow {
      margin-right: 80%;
      margin-top: 60%;
    }
  
    .video-background {
      margin-top: 12%;
    }
  
    #landing-background-img {
      margin-top: -60%;
    }
  
    #intranet-info-container {
      transform: scale(1.8);
      margin: auto;
      width: 50%;
    }
  
    #about-intranet-header {
      margin-top: 65%;
    }
  
    .footer-main-container {
      transform: scale(1.8);
      margin: auto;
      width: 50%;
    }
  
    .landing-footer {
      margin-top: 125%;
    }
  }

  @media (min-width: 1280px) and (max-width: 1919px) {

    .header {
      height: 75%;
    }
  
    #seed_logo_img {
      transform: scale(0.7);
      margin-top: 1%;
    }
  
    .title {
      transform: scale(0.7);
      margin-top: -5.5%;
    }
  
    .seed-main-buttons-container {
      transform: scale(0.7);
      width: 130%;
      margin: auto;
      margin-left: -14%;
      margin-top: -2%;
    }
  
    #by-text {
      transform: scale(0.7);
      margin-top: 1%;
    }
  
    #gorom_logo_img {
      margin-top: 0.2%;
      margin-bottom: 0.5%;
    }
  
    #learn-intranet-header {
      transform: scale(0.6);
      margin-left: 16%;
      margin-top: -5%;
    }
  
    .arrow-container {
      transform: scale(0.6);
    }
  
    #arrow {
      margin-right: 160%;
      margin-top: 20%;
    }
  
    .video-background {
      margin-top: 12%;
      transform: scale(1.2);
    }
  
    #landing-background-img {
      margin-top: -68%;
    }
  
    #intranet-info-container {
      transform: scale(0.7);
      margin: auto;
      width: 100%;
    }
  
    #about-intranet-header {
      margin-top: 40%;
    }
  
    .footer-main-container {
      transform: scale(0.7);
      margin: auto;
      width: 100%;
    }
  
    .landing-footer {
      margin-top: 18%;
    }

  }

  @media (max-width: 450px) {

    .header {
      height: auto;
      padding: 1rem 1rem;
      background-color: rgba(0, 0, 0, 0.6);
      margin-top: 15%;
    }

    #seed_logo_img {
      transform: scale(0.6);
      margin-top: 12%;
    }
  
    .title {
      font-size: 2.0rem;
      margin-top: -19%;
      transform: none; /* Reset scale to avoid overflowing */
    }
  
    .seed-main-buttons-container {
      transform: scale(0.7);
      width: 80%;
      margin: auto;
      margin-top: 1.5rem;
      flex-direction: column; /* Stack buttons vertically */
    }
  
    .seed-button {
      margin-bottom: 1rem;
      width: 100%;
      padding: 1rem;
      font-size: 1.2rem;
    }
  
    #by-text {
      font-size: 0.9rem;
      margin-top: 1rem;
      transform: none; /* Reset scale */
    }
  
    #gorom_logo_img {
      width: 40%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  
    #learn-intranet-header {
      transform: scale(0);
    }
  
    .arrow-container {
      transform: scale(0);
    }
  
    #arrow {
      margin-right: 0;
      width: 20%;
      margin-top: 2rem;
    }
  
    .video-background {
      transform: scale(1.3);
      height: 150%;
      width: 290%;
      margin-top: 50%;
      margin-bottom: -300%;
    }
  
    #landing-background-img {
      display: flex;
      margin-top: -120%;
      height: 50%;
    }
  
    #intranet-info-container {
      display: block;
      transform: scale(0.4);
      width: 180%;
      margin: auto;
      margin-left: -40%;
      margin-top: -145%;
    }
  
    #about-intranet-header {
      transform: scale(0.5);
      width: 180%;
      margin: auto;
      margin-left: -40%;
      margin-top: 28%;
    }
  
    .footer-main-container {
      transform: scale(0.4);
      margin-left: -40%;
      width: 180%;
    }
  
    .landing-footer {
      margin-top: 10rem;
    }
  }

    @media (max-width: 1100px) {

      .header {
        height: auto;
        padding: 1rem 1rem;
        background-color: rgba(0, 0, 0, 0.6);
        margin-top: 55%;
      }
  
      #seed_logo_img {
        transform: scale(1.2);
        margin-top: 12%;
      }
    
      .title {
        font-size: 3.0rem;
        transform: none; /* Reset scale to avoid overflowing */
      }
    
      .seed-main-buttons-container {
        transform: scale(0.9);
        width: 80%;
        margin: auto;
        margin-top: 1.5rem;
        flex-direction: column; /* Stack buttons vertically */
      }
    
      .seed-button {
        margin-bottom: 1rem;
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;
      }
    
      #by-text {
        font-size: 0.9rem;
        margin-top: 1rem;
        transform: none; /* Reset scale */
      }
    
      #gorom_logo_img {
        width: 40%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    
      #learn-intranet-header {
        transform: scale(0);
      }
    
      .arrow-container {
        transform: scale(0);
      }
    
      #arrow {
        margin-right: 0;
        width: 20%;
        margin-top: 2rem;
      }
    
      .video-background {
        height: 100%;
        width: 400%;
        margin-top: 150%;
      }
    
      #landing-background-img {
        display: flex;
        height: 50%;
        margin-top: 250%;
      }
    
      #intranet-info-container {
        display: block;
        transform: scale(0.4);
        width: 180%;
        margin: auto;
        margin-left: -40%;
        margin-top: -145%;
      }
    
      #about-intranet-header {
        transform: scale(0.5);
        width: 180%;
        margin: auto;
        margin-left: -40%;
        margin-top: 28%;
      }
    
      .footer-main-container {
        transform: scale(1.3);
        margin-left: -45%;
        width: 180%;
      }
    
      .landing-footer {
        margin-top: 10rem;
      }
  }