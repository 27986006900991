/* Header.css */

#permanent-header {
  background-color: #00b398;
  color: white; /* White text color */
  display: flex; /* Flexbox layout */
  align-items: center; /* Center items vertically */
  margin-top: 4.5%;
  height: 55px;
  z-index: 50; /* Ensure header is above the overlay */
  position: relative; /* Position relative to handle absolute positioned children */

  padding-left: 7%;
  padding-top: 1%;
  padding-right: 0%;
  padding-bottom: 1%;
}

#permanent-header h1 {
  margin: 0; /* Remove default margin */
  flex: 1; /* Fill remaining space */
}

#permanent-header button {
  background-color: #d14c4c; /* Red button background */
  color: white; /* White text color */
  border: none; /* Remove button border */
  padding: 8px 16px; /* Padding inside button */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  margin-left: 20px; /* Space between title and button */
}

#seed-intranet-header-logo {
  width: 10.5%;
  height: auto;
  position: relative;
  top: 110%;
  left: 2%;
  transform: translate(-50%, -50%);
  margin-right: -2%;
  z-index: 1; /* Ensure the logo is above other content */
  cursor: pointer; /* Show pointer cursor on hover */
}

#logout-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 80%;
}

#logout-button {
  font-size: 16px;
  font-weight: 600;
}

#header-go-to-menu-container-1 {
  margin-left: 2%;
  margin-right: 2%;
  cursor: pointer;
}

#header-go-to-menu-container-1:hover {
  transform: scale(1.05);
}

#header-sections-container {
  width: 18%;
  height: 120%;
  margin-left: 0.5%;
  margin-right: -30%;
  display: flex;
  cursor: pointer;
  position: relative; /* Position relative to handle absolute positioned deployable list */
}

#header-sections-container:hover {
  transform: scale(1.05);
}

#go-to-menu-button {
  font-size: 20px;
  font-weight: bold;
}

#intranet-sections-button {
  font-size: 20px;
  font-weight: bold;
  margin-top: 6%;
}

#header-expand-arrow {
  padding-left: 3.5%;
  padding-top: 1.5%;
  width: 3.5%;
  height: auto;
}

#deployable-list {
  left: -11%;
  position: absolute;
  top: calc(100% - 12px); /* Position below the container */
  background-color: #1fc363; /* Change the background color as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow for a cool effect */
  border-radius: 10px; /* Add some rounded corners */
  width: 70%; /* Set a fixed width or adjust as needed */
  display: flex;
}

#deployable-list ul {
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0;
}

#deployable-list li {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

#deployable-list li:hover {
  background-color: #0b3245; /* Change the background color on hover */
}
