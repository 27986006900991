
.club-5-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0%;
    margin-top: -1%;
    background-color: rgb(255, 253, 215);
}

.club-content {
    display: flex;
    width: 80%;
    margin: 20px auto;
}

.club-5-header {
    background-color: #c59446;
    color: white;
    padding: 10px;
    border-radius: 3%;
    margin-bottom: 3%;
}

.main-content {
    flex: 0 0 70%;
    padding: 20px;
}

.sidebar {
    margin-top: 10%;
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.club-banner {
    width: 60%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 2%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.club-description {
    text-align: justify;
    font-size: 17px;
}

.sidebar-5-button {
    margin-left: 15%;
    width: 80%;
    text-decoration: none;
    color: white;
    background-color: #c59446;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.sidebar-header {
    margin-left: 10%;
}

.sidebar-button:hover {
    background-color: #0056b3;
}
