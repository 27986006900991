/* RecommendationsPadlet.css */

.padlet-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 0 auto;
    position: relative;
    min-height: 55vh;
    z-index: 1;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 2;
}

.add-note-button {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #f59652; 
    color: white;
    border: none;
    font-size: 35px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.add-note-button:hover {
    background-color: #0056b3;
}

.recommendations-title {
    font-size: 190%;
    font-weight: bold;
}

.recommendations-header {
    margin-bottom: 4%;
    margin-top: 3%;
}

.add-note-container {
    display: flex;
    justify-content: right;
    margin-bottom: 2%;
}

#indications-text{
    margin-top: -3%;
    margin-bottom: 5%;
    font-weight: 400;
}