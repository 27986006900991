.documents-groups-container {
    max-width: 960px;
    margin: 20px auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
}

.documents-groups-header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.documents-groups-header h1 {
    margin: 0;
    color: #333;
}

.categories-header {
    font-size: 240%;
}

.documents-groups-header p {
    font-size: 16px;
    color: #666;
}

.documents-group-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 20px;
    padding: 25px;
}

.document-group {
    background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 90px; /* Consistent height for all buttons */
    overflow: hidden; /* Ensures no overflow of content */
    border: none;
    width: 100%;
}

.document-icon {
    width: 50px;
    margin-right: 1%;
}

.document-group:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
