/* EditingForum.css */
.editing-forum-modal {
    position: fixed;
    top: 20%;
    left: 0;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .editing-forum-content {
    background-color: white;
    padding: 30px;
    width: 40%;
    height: 500px;
    border-radius: 10px;
  }

  #editing-forum-textarea{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }

  #editing-forum-title{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .editing-forum-content h2 {
    margin-top: 0;
  }
  
  .editing-forum-content input,
  .editing-forum-content textarea {
    width: 100%;
    margin-bottom: 10px;
  }

  .editing-forum-content textarea{
    height: 300px;
    margin-top: 1%;
  }
  
  .editing-forum-buttons {
    display: flex;
    justify-content: center;
  }
  
  .editing-forum-buttons button {
    margin-left: 15px;
    width: 30%;
    border-radius: 3px;
    padding: 5px;
    margin-top: 3%;
  }

  #save-forum-edit-button{
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }

  #cancel-forum-edit-button{
    background-color: #f44336;
    color: white;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
  }