.groups-container {
    max-width: 600px;
    margin: 20px auto;
    margin-bottom: 4%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
}

.group-button {
    background: linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%);
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 80px; /* Consistent height for all buttons */
    overflow: hidden; /* Ensures no overflow of content */
    border: none;
    width: 80%;
    margin-bottom: 2%;
}

.group-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
