/* Colors */

.calendar-section{
  transform: scale(1.1);
  transition: all 0.3s ease; 
  width: 65%;
  grid-area: calendar;
  margin-top: 2%;
  z-index: 1;
}

.calendar-container {
  padding: 20px;
  transition: all 0.3s ease; /* Smooth transition for layout changes */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%; /* Adjusted for better responsiveness */
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;

  margin-bottom: -10%;
}

/* Centered layout for when no tasks are selected */
.calendar-container.centered {
  flex-direction: column;
  align-items: center;
}

/* Side-by-side layout for when tasks are selected */
.calendar-container.side-by-side {
  display: grid;
  grid-template-columns: 5fr 1fr; /* Two equal columns */
  grid-template-areas: 
    "header header"
    "subheader subheader"
    "calendar tasks"
    "button button";
  align-items: start;
  margin-left: 18%;
  margin-bottom: -10%;
}


.calendar-header {
  font-size: 260%;
  margin-top: 3%;
  margin-bottom: 3%;
  grid-area: header;
}

.calendar-subheader {
  margin-top: -1%;
  font-size: 110%;
  margin-bottom: 4%;
  grid-area: subheader;
}

.custom-calendar {
width: 100%;
height: 100%;
background-color: #fff;
border: 2px solid #ccc;
border-radius: 10px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.calendar-left-section {
margin-top: 4%;
width: 120%;
margin-left: -80%;
justify-content: left;
align-items: left;
padding: 10px;
border-bottom: 1px solid #ccc;
}

/* Highlight days with tasks */
.react-calendar__tile .highlight {
background-color: #f96800;
border-radius: 50%;
width: 12px;
height: 12px;
right: -10px;
display: inline-block;
position: relative;
transform: translate(-50%, -50%);
}

/* Customize the header of the calendar */
.react-calendar__navigation button {
  font-weight: bold;
  font-size: 110%;
  color: #000000; /* Header text color */
  background-color: #f7a971; /* Header background color */
  border: none;
  border-radius: 5px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ef8a42; /* Header button hover and focus color */
}

.react-calendar__tile--now {
  background: #ef8a42; /* Current day background color */
  color: white; /* Current day text color */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ef8a42; /* Current day hover and focus color */
}

/* Customize the days of the week */
.react-calendar__month-view__weekdays {
  background-color: #ffffff; /* Weekdays background color */
  color: #000000; /* Weekdays text color */
  font-weight: bold;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #fda085; /* Active day hover and focus color */
}

/* New styles for the tasks popup */
.close-button-container {
position: relative;
cursor: pointer;
margin-left: 91%;
margin-top: 4%;
margin-bottom: -3%;
}

#task-popup-close{
  background-color: #f44336;
}

.tasks-main-container {
position: fixed;
top: 52%;
left: 68%;
transform: translate(-50%, -50%);
z-index: 2; /* Place the tasks popup above other elements */
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
margin-left: 3%;
grid-area: tasks;
}

.task-popup {
  margin-top: 0%;
  margin-bottom: 5%;
  width: 30%;
  max-width: 35%; /* Limit the max width of the popup */
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  opacity: 1;
}

.task-popup:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.task-popup h2 {
font-weight: bold;
color: #333;
}

.task-popup ul {
  padding: 0;
  list-style-type: decimal;
  margin-left: 7%;
  margin-bottom: 5%;
}

.task-popup li {
  padding: 2%;
  background-color: #f9f9f9;
  border-radius: 5px;
  word-wrap: break-word; /* Ensures text wraps within the element */
  white-space: pre-wrap; /* Preserves white spaces and wraps text */
  overflow-wrap: break-word; /* Breaks long words to wrap */
  margin-right: 6%;
}

/* Animation for task popup */
@keyframes fadeIn {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}

@keyframes fadeOut {
from {
  opacity: 1;
}
to {
  opacity: 0;
}
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .calendar-container.side-by-side {
    grid-template-columns: 1fr; /* Stack everything in one column on smaller screens */
    grid-template-areas:
      "header"
      "subheader"
      "calendar"
      "tasks"
      "button";
  }

  .calendar-section, .tasks-main-container {
    min-height: auto; /* Allow containers to resize according to content on smaller screens */
  }
}

/* Add task button for admins */

.add-task-button {
  padding: 10px 10px;
  background-color: #f7a971;
  color: rgb(0, 0, 0);
  font-weight: bolder;
  border: 0.5px solid #434343;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 6%;
  margin-bottom: 50%;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  z-index: 2;
}

.add-task-button:hover {
  background-color: #ef8a42;
}

.add-task-container {
  width: 40%;
  margin-left: -5%;
  grid-area: button;
  margin-top: -24%;
  z-index: 2;
}

.calendar-section, .tasks-main-container {
  min-height: 600px; /* Adjust this value based on your content needs */
}

#pencil-icon {
  display: fixed;
  position: relative;
  cursor: pointer;
  font-size: 100%;
  color: #234cee;
}